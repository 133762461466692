import { CommonObject, CommonObjectDTO } from "./common-object";

export interface UserDTO extends CommonObjectDTO {
  username: string;
  password?: string;
  email: string;
  name: string;
  surname: string;
  admin?: boolean;
  gender?: string;
  profile_pic?: string;
}

export class User extends CommonObject {
  username: string;
  password?: string;
  email: string;
  name: string;
  surname: string;
  admin: boolean = false;
  gender?: string;
  profilePic?: string;

  constructor(dto: UserDTO) {
    super(dto);
    if (dto) {
      this.username = dto.username;
      this.name = dto.name;
      this.surname = dto.surname;
      this.email = dto.email;
      this.gender = dto.gender;
      this.admin = Boolean(dto.admin);
      this.profilePic = dto.profile_pic;
    }
  }

  public toDTO(): UserDTO {
    let result: UserDTO = <UserDTO>super.toDTO();
    Object.assign(result, {
      username: this.username,
      name: this.name,
      surname: this.surname,
      email: this.email,
      gender: this.gender,
      admin: this.admin,
      profile_pic: this.profilePic,
      password: this.password
    });
    return result;
  }

  get fullName() {
    return `${this.name} ${this.surname}`;
  }
}
