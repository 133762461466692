import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { environment } from "../../../environments/environment";

@Injectable({ providedIn: "root" })
export class LaravelMailService {
  constructor(private httpClient: HttpClient) {}

  private get ROUTES() {
    return {
      sendMail: environment["laravel"]["serverUrl"] + "/api/sendMail",
    };
  }

  sendMail(
    sender: string,
    subject: string,
    content: string,
    participant_ids: number[],
    planned_unit_id: number,
    sender_in_bcc: boolean = false,
    include_certificates: boolean = false,
    include_participants: boolean = false
  ): Observable<boolean> {
    return this.httpClient.post<boolean>(this.ROUTES.sendMail, {
      sender: sender,
      subject: subject,
      content: content,
      participant_ids: participant_ids,
      planned_unit_id: planned_unit_id,
      sender_in_bcc: sender_in_bcc,
      include_certificates: include_certificates,
      include_participants: include_participants,
    });
  }
}
