import { Component } from "@angular/core";
import { UserService } from "./_services/user.service";
import { Observable } from "rxjs";
import { User } from "./_models/user";
import { Store } from "@ngrx/store";
import { getCurrentUser, State } from "./_reducers";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent {
  title = "app";

  currentUser: Observable<User> = this.store.select(getCurrentUser);
  constructor(private userService: UserService, private store: Store<State>) {}
}
