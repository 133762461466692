import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { RecoveryDetailsDTO } from 'app/_models/planning';

@Component({
  selector: "app-recovery-details-dialog",
  templateUrl: "./recovery-details-dialog.component.html",
  styleUrls: ["./recovery-details-dialog.component.scss"],
})
export class RecoveryDetailsDialogComponent {
  form: FormGroup = this.fb.group({
    cost: [],
    status: [],
  });

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<RecoveryDetailsDialogComponent>
  ) {}

  close() {
    const result: RecoveryDetailsDTO = {
      cost: this.form.value.cost,
      status: this.form.value.status,
    };

    this.dialogRef.close(result);
  }

  cancel() {
    this.dialogRef.close({ cancel: true });
  }
}
