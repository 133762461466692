import { ActionReducerMap, MetaReducer, createSelector } from "@ngrx/store";

import * as fromApp from "./app.reducer";

export interface State {
  appState: fromApp.AppState;
}

export const reducers: ActionReducerMap<State> = {
  appState: fromApp.reducer
};

export const metaReducers: MetaReducer<State>[] = [fromApp.clearState];

const getAppState = (state: State) => state.appState;

export const getCurrentUser = createSelector(
  getAppState,
  fromApp.currentUser
);

export const getServices = createSelector(
  getAppState,
  fromApp.services
);

export const getCurrentPageTitle = createSelector(
  getAppState,
  fromApp.currentPageTitle
);

export const getCurrentPageSlug = createSelector(
  getAppState,
  fromApp.currentPageSlug
);