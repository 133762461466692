import { FormGroup, FormBuilder } from "@angular/forms";
import { SelectionModel } from "@angular/cdk/collections";
import { Component, Inject, Input, OnInit, ViewChild } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatPaginator,
  MatSort
} from "@angular/material";
import { EducationUnit } from "app/_models/service";
import { merge, Observable } from "rxjs";
import { map, take, tap } from "rxjs/operators";

import { PlannedUnit } from "../../_models/planning";
import {
  PlannedUnitService,
  PlannedUnitFilter
} from "../../_services/planned-unit.service";
import { PlannedUnitDataSource } from "./planned-unit.datasource";

@Component({
  selector: "app-planned-unit-selection-dialog",
  templateUrl: "./planned-unit-selection-dialog.component.html",
  styleUrls: ["./planned-unit-selection-dialog.component.scss"]
})
export class PlannedUnitSelectionDialogComponent implements OnInit {
  dataSource: PlannedUnitDataSource;

  pageSizeOptions = [10, 25, 50, 100];

  selection = new PlannedUnitSelectionModel(true, []);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  multiple: boolean;
  disabledIds: number[] = [];

  @Input()
  educationUnit: EducationUnit;

  filtersForm: FormGroup;

  constructor(
    private plannedUnitService: PlannedUnitService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<PlannedUnitSelectionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    if (this.data) {
      this.educationUnit = this.data.educationUnit;
      this.multiple = this.data.multiple;
      this.disabledIds = this.data.disabledIds || [];
    }
    this.dataSource = new PlannedUnitDataSource(this.plannedUnitService);
    this.createFiltersForm();
    this.initFiltersForm();
    this.loadPlannedUnits();
  }

  get displayedColumns(): string[] {
    return this.multiple
      ? ["select", "date", "location"]
      : ["date", "location", "action"];
  }

  private createFiltersForm() {
    let group = {
      educationUnitId: [
        this.educationUnit ? this.educationUnit.objectId : null
      ],
      from: [],
      to: []
    };
    this.filtersForm = this.fb.group(group);
  }

  initFiltersForm() {
    if (this.filtersForm) {
      this.filtersForm.reset();
      this.filtersForm.patchValue({
        educationUnitId: this.educationUnit
          ? this.educationUnit.objectId
          : null,
        from: new Date(),
        to: null
      });
    }
  }

  private filterFromForm(): PlannedUnitFilter {
    let filter = new PlannedUnitFilter();
    if (this.filtersForm) {
      filter.educationUnitId = this.filtersForm.value.educationUnitId;
      filter.startDate = this.filtersForm.value.from;
      filter.endDate = this.filtersForm.value.to;
    }
    return filter;
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.loadPlannedUnits()))
      .subscribe();
  }

  get allSelected(): Observable<boolean> {
    return this.dataSource.data.pipe(
      map(datas => {
        const numSelected = this.selection.selected.length;
        return datas && datas.length === numSelected;
      })
    );
  }

  canSelect(plannedUnit: PlannedUnit): boolean {
    return this.disabledIds.indexOf(plannedUnit.objectId) == -1;
  }

  masterToggle() {
    this.allSelected.pipe(take(1)).subscribe(all => {
      if (all) {
        this.selection.clear();
      } else {
        this.dataSource.data.pipe(take(1)).subscribe(datas => {
          datas.forEach(row => {
            if (this.canSelect(row)) {
              this.selection.select(row);
            }
          });
        });
      }
    });
  }

  loadPlannedUnits() {
    return this.dataSource.loadPlannedUnits(
      this.paginator.pageIndex + 1,
      this.paginator.pageSize,
      this.sort.active,
      this.sort.direction,
      this.filterFromForm()
    );
  }

  reload() {
    this.paginator.pageIndex = 0;
    this.paginator._changePageSize(this.paginator.pageSize);
    this.loadPlannedUnits();
  }

  selectPlannedUnit(plannedUnit: PlannedUnit) {
    this.dialogRef.close(plannedUnit);
  }

  confirmMultiple() {
    this.dialogRef.close(this.selection.selected);
  }

  close() {
    this.dialogRef.close();
  }
}

class PlannedUnitSelectionModel extends SelectionModel<PlannedUnit> {
  isSelected(plannedUnit: PlannedUnit): boolean {
    let exists = this.selected.find(element => {
      return element.objectId == plannedUnit.objectId;
    });
    return exists != undefined;
  }
}
