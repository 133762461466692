import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ListResultDTO } from '../../_helpers/listResult.interface';
import { environment } from './../../../environments/environment';
import { Org, OrgDTO } from './../../_models/org';
import { EmployeeDTO } from 'app/_models/employee';

@Injectable({ providedIn: "root" })
export class LaravelOrgService {
  constructor(private httpClient: HttpClient) { }

  private get ROUTES() {
    return {
      show: environment["laravel"]["serverUrl"] + "/api/org",
      list: environment["laravel"]["serverUrl"] + "/api/orgs",
      store: environment["laravel"]["serverUrl"] + "/api/org",
      restore: environment["laravel"]["serverUrl"] + "/api/org/restore",
      delete: environment["laravel"]["serverUrl"] + "/api/org",
      checkVATExisting:
        environment["laravel"]["serverUrl"] + "/api/checkVATExisting",
      importOrg: environment["laravel"]["serverUrl"] + "/api/importOrg",
      export_status:
        environment["laravel"]["serverUrl"] + "/api/org/export_status",
      export_status_partner:
        environment["laravel"]["serverUrl"] + "/api/org/export_status_partner",
      import_employees:
        environment["laravel"]["serverUrl"] + "/api/org/import_employees",
    };
  }

  getOrgById(id: number, include?: string[]): Observable<OrgDTO> {
    let params = {
      id: "" + id
    };
    if (include) params["include[]"] = include;
    return this.httpClient.get<OrgDTO>(this.ROUTES.show, {
      params: new HttpParams({
        fromObject: params
      })
    });
  }

  public getOrgs(
    page: number,
    per_page: number,
    order: string,
    direction: string,
    only_partners?: boolean,
    include_archived?: boolean,
    filter?: string,
    include?: string[]
  ): Observable<ListResultDTO<OrgDTO>> {
    let params = {};
    if (order) params["order"] = "" + order;
    if (direction) params["direction"] = "" + direction;
    if (include) params["include[]"] = include;
    if (only_partners) params["only_partners"] = "" + only_partners;
    if (filter) params["filter"] = "" + filter;
    if (include_archived) params["include_archived"] = "" + true;

    if (per_page) {
      if (page) params["page"] = "" + page;
      if (per_page) params["per_page"] = "" + per_page;
      return this.httpClient.get<ListResultDTO<OrgDTO>>(this.ROUTES.list, {
        params: new HttpParams({ fromObject: params })
      });
    } else {
      return this.httpClient
        .get<OrgDTO[]>(this.ROUTES.list, {
          params: new HttpParams({ fromObject: params })
        })
        .pipe(
          map(results => {
            return {
              data: results,
              total: results.length
            };
          })
        );
    }
  }

  public createOrg(dto: OrgDTO, logo?: any): Observable<OrgDTO> {
    let formData: FormData = new FormData();
    formData.append("org", JSON.stringify(dto));
    if (logo) {
      formData.append("logo", logo);
    }
    return this.httpClient.post<OrgDTO>(this.ROUTES.store, formData);
  }

  public updateOrg(id: number, dto: OrgDTO, logo?: any): Observable<OrgDTO> {
    dto.id = id;
    let formData: FormData = new FormData();
    formData.append("org", JSON.stringify(dto));
    if (logo) {
      formData.append("logo", logo);
    }
    return this.httpClient.post<OrgDTO>(this.ROUTES.store, formData); //mrosetti - Using PUT instead of POST will break the entire request, don't do it
  }

  public deleteOrg(id) {
    let params = new HttpParams().set("id", "" + id);
    return this.httpClient.delete(this.ROUTES.delete, { params: params });
  }

  public restoreOrg(id): Observable<OrgDTO> {
    return this.httpClient.put<OrgDTO>(this.ROUTES.restore, { id: id });
  }

  public checkVATExisting(orgId: number, VAT: string): Observable<boolean> {
    let params = {};
    if (VAT) params["VAT"] = "" + VAT;
    if (orgId) params["org_id"] = "" + orgId;
    return this.httpClient.get<boolean>(this.ROUTES.checkVATExisting, {
      params: new HttpParams({ fromObject: params })
    });
  }

  public importEmployees(orgId: number,  employees: any): Observable<EmployeeDTO[]> {
    let formData: FormData = new FormData();
    formData.append("employees", employees);
    formData.append("org_id", "" + orgId);

    return this.httpClient.post<EmployeeDTO[]>(this.ROUTES.import_employees, formData);
  }

  importOrg(orgId: number, data: any): Observable<any> {
    let params = {
      org_id: orgId,
      import_data: data
    };
    return this.httpClient.post(this.ROUTES.importOrg, { params: params });
  }


  public exportStatus(id: number): Observable<Blob> {
    let params = {
      id: "" + id
    };
    return this.httpClient.get(this.ROUTES.export_status, {
      params: new HttpParams({
        fromObject: params
      }),
      responseType: "blob"
    });
  }

  public exportStatusPartner(id: number): Observable<Blob> {
    let params = {
      id: "" + id
    };
    return this.httpClient.get(this.ROUTES.export_status_partner, {
      params: new HttpParams({
        fromObject: params
      }),
      responseType: "blob"
    });
  }

}


