import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatPaginator, MatSort } from '@angular/material';
import { merge, Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

import { Employee } from './../../_models/employee';
import { Org } from './../../_models/org';
import { EmployeeService } from './../../_services/employee.service';
import { EmployeeDataSource } from './../../home/employees/employee-list/employee.datasource';
import { Presence } from 'app/_models/planning';

@Component({
  selector: "app-employee-selection-dialog",
  templateUrl: "./employee-selection-dialog.component.html",
  styleUrls: ["./employee-selection-dialog.component.scss"]
})
export class EmployeeSelectionDialogComponent implements OnInit {
  displayedColumns = ["name", "VAT", "city", "contact", "action"];
  dataSource: EmployeeDataSource;

  pageSizeOptions = [10, 25, 50, 100];
  queryString: string;

  selection = new EmployeeSelectionModel(true, []);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  org: Org;
  multiple: boolean;
  disabledIds: number[] = [];


  onlyPrivate: boolean = true;

  constructor(
    private employeeService: EmployeeService,
    public dialogRef: MatDialogRef<EmployeeSelectionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    if (this.data) {
      this.org = this.data.org;
      this.multiple = this.data.multiple;
      this.disabledIds = this.data.disabledIds || [];
    }
    if (this.multiple) {
      this.displayedColumns = ["select", "name", "surname"];
    } else {
      this.displayedColumns = ["name", "surname", "action"];
    }
    this.dataSource = new EmployeeDataSource(this.employeeService);
    this.loadEmployees();
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.loadEmployees()))
      .subscribe();
  }

  get allSelected(): Observable<boolean> {
    return this.dataSource.data.pipe(
      map(datas => {
        const numSelected = this.selection.selected.length;
        return datas && datas.length === numSelected;
      })
    );
  }

  canSelect(employee: Employee): boolean {
    return this.disabledIds.indexOf(employee.objectId) == -1;
  }

  masterToggle() {
    this.allSelected.pipe(take(1)).subscribe(all => {
      if (all) {
        this.selection.clear();
      } else {
        this.dataSource.data.pipe(take(1)).subscribe(datas => {
          datas.forEach(row => {
            if (this.canSelect(row)) {
              this.selection.select(row);
            }
          });
        });
      }
    });
  }

  loadEmployees() {
    return this.dataSource.loadEmployees(
      this.org,
      this.onlyPrivate,
      this.paginator.pageIndex + 1,
      this.paginator.pageSize,
      this.sort.active,
      this.sort.direction,
      { queryString: this.queryString, includeArchived: false }, ["org"]
    );
  }

  reload() {
    this.paginator.pageIndex = 0;
    this.paginator._changePageSize(this.paginator.pageSize);
    this.loadEmployees();
  }

  selectEmployee(employee: Employee) {
    this.dialogRef.close(employee);
  }
  
  confirmMultiple() {
    this.dialogRef.close(this.selection.selected);
  }

  close() {
    this.dialogRef.close();
  }
}

class EmployeeSelectionModel extends SelectionModel<Employee> {
  isSelected(employee: Employee): boolean {
    let exists = this.selected.find(element => {
      return element.objectId == employee.objectId;
    });
    return exists != undefined;
  }
}
