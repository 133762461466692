import { State } from "./../_reducers/index";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";

import { User } from "../_models/user";
import { LaravelUserService } from "./laravel/laravel-user.service";
import { catchError, map, flatMap } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
import { ListResult } from "../_helpers/listResult.interface";
import { Store } from "@ngrx/store";
import { SetCurrentUser, SignOut, SignIn } from "../_reducers/app.actions";
import { StorageService } from "./storage.service";
import { AUTH_TOKEN } from "../_helpers/storage.helper";
import { getCurrentUser } from "../_reducers";

@Injectable({ providedIn: "root" })
export class UserService {
  constructor(
    private laravelUserService: LaravelUserService,
    private store: Store<State>,
    private storageService: StorageService
  ) {}

  loadCurrentUser(): Promise<User> {
    return this.laravelUserService
      .getCurrentUser()
      .pipe(
        map(dto => {
          let user = new User(dto);
          this.setCurrentUser(user);
          return user;
        }),
        catchError(error => {
          if (error && error instanceof HttpErrorResponse) {
            if (error.status == 401) {
              return Promise.resolve(null);
            }
          }
          return Promise.reject(error);
        })
      )
      .toPromise();
  }

  private setCurrentUser(user: User) {
    this.store.dispatch(new SetCurrentUser({ user: user }));
  }

  login(email: string, password: string): Observable<User> {
    return this.laravelUserService.login(email, password).pipe(
      map(result => {
        this.afterLogin(result.success.token);
        let user: User = new User(result.success.user);
        this.store.dispatch(new SignIn({ user: user }));
        return user;
      })
    );
  }

  private afterLogin(token: string): void {
    this.storageService.set(AUTH_TOKEN, token);
  }

  isAuthenticated(): Observable<boolean> {
    return this.store.select(getCurrentUser).pipe(map(user => user != null));
  }

  get isAdmin(): Observable<boolean> {
    return of(true); //TODO mrosetti - Da implementare
  }

  logout() {
    return this.laravelUserService.logout().pipe(
      map(() => {
        this.store.dispatch(new SignOut());
      })
    );
  }

  getUser(userId: number): Observable<User> {
    return this.laravelUserService
      .getUserById(userId)
      .pipe(map(dto => new User(dto)));
  }

  getUsers(
    page: number,
    perPage: number,
    order: string,
    direction: string,
    filter?: string,
    include?: string | string[]
  ): Observable<ListResult<User>> {
    let includes = typeof include === "string" ? [include] : include;
    return this.laravelUserService
      .getUsers(page, perPage, order, direction, filter, includes)
      .pipe(
        map(response => {
          return {
            data: response.data.map(dto => new User(dto)),
            total: response.total
          };
        })
      );
  }

  public createUser(user: User): Observable<User> {
    return this.laravelUserService.createUser(user.toDTO()).pipe(
      map(result => {
        return new User(result);
      })
    );
  }

  public updateUser(user: User): Observable<User> {
    return this.laravelUserService.updateUser(user.objectId, user.toDTO()).pipe(
      map(result => {
        return new User(result);
      })
    );
  }

  public updatePassword(userId: number, newPassword: string) {
    return this.laravelUserService.changePassword(userId, newPassword).pipe(
      map(result => {
        return new User(result);
      })
    );
  }

  public archiveUser(user: User): Observable<User> {
    return this.laravelUserService.archiveUser(user.objectId).pipe(
      map(result => {
        return new User(result);
      })
    );
  }
}
