import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import {
  MAT_DATE_LOCALE,
  MAT_DIALOG_DEFAULT_OPTIONS,
  MAT_DATE_FORMATS,
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorIntl,
  MatPaginatorModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatSortModule,
  MatTableModule,
  MatToolbarModule,
  MatTooltipModule,
  DateAdapter,
  MatProgressBarModule,
  MatSnackBarModule,
  MatSlideToggleModule,
  MatBadgeModule
} from "@angular/material";

import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter
} from "@angular/material-moment-adapter";

import { getItalianPaginatorIntl } from "./italian-paginator";

@NgModule({
  imports: [CommonModule, MatNativeDateModule],
  exports: [
    MatToolbarModule,
    MatButtonToggleModule,
    MatCardModule,
    MatInputModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatSnackBarModule,
    MatListModule,
    MatChipsModule,
    MatSelectModule,
    MatMenuModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatProgressBarModule,
    MatSlideToggleModule,
    MatBadgeModule
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: "it-IT" },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: MatPaginatorIntl, useValue: getItalianPaginatorIntl() },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: { hasBackdrop: true, disableClose: true, maxHeight: "95%" }
    }
  ]
})
export class AppMaterialModule {}
