import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatPaginator, MatSort } from '@angular/material';
import { PlannedCourse } from 'app/_models/planning';
import { merge, Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

import { Org } from '../../_models/org';
import { PlannedCourseFilters, PlanningService } from '../../_services/planning.service';
import { PlannedCourseDataSource } from '../../home/planning/planning-list/planned-course.datasource';
import { PlannedCourseStatusEnum } from './../../_models/planning';

@Component({
  selector: "app-planned-course-selection-dialog",
  templateUrl: "./planned-course-selection-dialog.component.html",
  styleUrls: ["./planned-course-selection-dialog.component.scss"]
})
export class PlannedCourseSelectionDialogComponent implements OnInit {
  dataSource: PlannedCourseDataSource;

  pageSizeOptions = [10, 25, 50, 100];

  plannedOrderStatuses = PlannedCourseStatusEnum;
  filtersForm: FormGroup;

  selection = new PlannedCourseSelectionModel(true, []);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  org: Org;
  multiple: boolean;
  disabledIds: number[] = [];

  onlyPrivate: boolean = true;

  constructor(
    private planningService: PlanningService,
    public dialogRef: MatDialogRef<PlannedCourseSelectionDialogComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    if (this.data) {
      this.org = this.data.org;
      this.multiple = this.data.multiple;
      this.disabledIds = this.data.disabledIds || [];
    }
    this.dataSource = new PlannedCourseDataSource(this.planningService);
    this.createFiltersForm();
    this.initFiltersForm();
    this.loadPlannedCourses();
  }

  private createFiltersForm() {
    let group = {
      query: [""],
      statuses: []
    };
    this.filtersForm = this.fb.group(group);
  }

  initFiltersForm() {
    if (this.filtersForm) {
      this.filtersForm.reset();
      this.filtersForm.patchValue({
        query: "",
        statuses: ["draft", "planned", "cancelled"]
      });
    }
  }

  private filterFromForm(): PlannedCourseFilters {
    let filter = new PlannedCourseFilters();
    if (this.filtersForm) {
      filter.query = this.filtersForm.value.query;
      filter.statuses = this.filtersForm.value.statuses;
    }
    return filter;
  }

  get displayedColumns(): string[] {
    return this.multiple
      ? ["select", "id", "status", "course", "from", "to"]
      : ["id", "status", "course", "from", "to", "action"];
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.loadPlannedCourses()))
      .subscribe();
  }

  get allSelected(): Observable<boolean> {
    return this.dataSource.data.pipe(
      map(datas => {
        const numSelected = this.selection.selected.length;
        return datas && datas.length === numSelected;
      })
    );
  }

  canSelect(plannedCourse: PlannedCourse): boolean {
    return this.disabledIds.indexOf(plannedCourse.objectId) == -1;
  }

  masterToggle() {
    this.allSelected.pipe(take(1)).subscribe(all => {
      if (all) {
        this.selection.clear();
      } else {
        this.dataSource.data.pipe(take(1)).subscribe(datas => {
          datas.forEach(row => {
            if (this.canSelect(row)) {
              this.selection.select(row);
            }
          });
        });
      }
    });
  }

  loadPlannedCourses() {
    return this.dataSource.loadPlannedCourses(
      this.paginator.pageIndex + 1,
      this.paginator.pageSize,
      this.sort.active,
      this.sort.direction,
      this.filterFromForm(),
      [
        "service",
        "planned_units.teachers",
        "parent.service",
        "planned_units.resources",
        "childs.participants",
        "participants", 
        "participants.presences.recovers"
      
      ]
    );
  }

  reload() {
    this.paginator.pageIndex = 0;
    this.paginator._changePageSize(this.paginator.pageSize);
    this.loadPlannedCourses();
  }

  selectPlannedCourse(plannedCourse: PlannedCourse) {
    this.dialogRef.close(plannedCourse);
  }

  confirmMultiple() {
    this.dialogRef.close(this.selection.selected);
  }

  close() {
    this.dialogRef.close();
  }
}

class PlannedCourseSelectionModel extends SelectionModel<PlannedCourse> {
  isSelected(plannedCourse: PlannedCourse): boolean {
    let exists = this.selected.find(element => {
      return element.objectId == plannedCourse.objectId;
    });
    return exists != undefined;
  }
}
