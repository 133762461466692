import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { ListResultDTO } from "../../_helpers/listResult.interface";
import { formatDateForBackend } from "../../_helpers/utils";
import { OrgDTO } from "../../_models/org";
import { environment } from "./../../../environments/environment";
import {
  DeadlineDTO,
  DeadlineFilter,
  EmployeeDTO,
} from "./../../_models/employee";

@Injectable({ providedIn: "root" })
export class LaravelDeadlineService {
  constructor(private httpClient: HttpClient) {}

  private get ROUTES() {
    return {
      show: environment["laravel"]["serverUrl"] + "/api/deadline",
      list: environment["laravel"]["serverUrl"] + "/api/deadlines",
      store: environment["laravel"]["serverUrl"] + "/api/deadline",
      delete: environment["laravel"]["serverUrl"] + "/api/deadline",
      orgsWithDeadline:
        environment["laravel"]["serverUrl"] + "/api/orgsWithDeadline",
      employeesWithDeadline:
        environment["laravel"]["serverUrl"] + "/api/employeesWithDeadline",
      batchUpdateDeadlines:
        environment["laravel"]["serverUrl"] + "/api/batchUpdateDeadlines",
      sendReminder: environment["laravel"]["serverUrl"] + "/api/sendReminder",
      checkExistingDeadlineForEmployee:
        environment["laravel"]["serverUrl"] +
        "/api/checkExistingDeadlineForEmployee",
    };
  }

  getDeadlineById(id: number, include?: string[]): Observable<DeadlineDTO> {
    let params = {
      id: "" + id,
    };
    if (include) params["include[]"] = include;
    return this.httpClient.get<DeadlineDTO>(this.ROUTES.show, {
      params: new HttpParams({
        fromObject: params,
      }),
    });
  }

  getOrgsWithDeadlines(
    page?: number,
    per_page?: number,
    order?: string,
    direction?: string,
    filter?: DeadlineFilter,
    include?: string[]
  ): Observable<ListResultDTO<OrgDTO>> {
    let params = {};
    if (order) params["order"] = "" + order;
    if (direction) params["direction"] = "" + direction;
    if (include) params["include[]"] = include;
    if (filter) {
      if (filter.includeArchived) params["include_archived"] = "true";
      if (filter.serviceId) params["service_id"] = "" + filter.serviceId;
      if (filter.reminderStart)
        params["reminder_start"] =
          "" + formatDateForBackend(filter.reminderStart);
      if (filter.reminderEnd)
        params["reminder_end"] = "" + formatDateForBackend(filter.reminderEnd);
      if (filter.expirationStart)
        params["expiration_start"] =
          "" + formatDateForBackend(filter.expirationStart);
      if (filter.expirationEnd)
        params["expiration_end"] =
          "" + formatDateForBackend(filter.expirationEnd);
      if (filter.employeeQueryString)
        params["employee_query_string"] = filter.employeeQueryString;
      if (filter.orderQueryString)
        params["order_query_string"] = filter.orderQueryString;
    }
    if (per_page) {
      params["page"] = "" + page;
      params["per_page"] = "" + per_page;
      return this.httpClient.get<ListResultDTO<OrgDTO>>(
        this.ROUTES.orgsWithDeadline,
        {
          params: new HttpParams({ fromObject: params }),
        }
      );
    } else {
      return this.httpClient
        .get<OrgDTO[]>(this.ROUTES.orgsWithDeadline, {
          params: new HttpParams({ fromObject: params }),
        })
        .pipe(
          map((results) => {
            return {
              data: results,
              lenght: results.length,
            };
          })
        );
    }
  }

  getEmployeesWithDeadline(
    page?: number,
    per_page?: number,
    order?: string,
    direction?: string,
    filter?: DeadlineFilter,
    include?: string[]
  ): Observable<ListResultDTO<EmployeeDTO>> {
    let params = {};
    if (order) params["order"] = "" + order;
    if (direction) params["direction"] = "" + direction;
    if (include) params["include[]"] = include;
    if (filter) {
      if (filter.includeArchived) params["include_archived"] = "true";
      if (filter.serviceId) params["service_id"] = "" + filter.serviceId;
      if (filter.reminderStart)
        params["reminder_start"] =
          "" + formatDateForBackend(filter.reminderStart);
      if (filter.reminderEnd)
        params["reminder_end"] = "" + formatDateForBackend(filter.reminderEnd);
      if (filter.expirationStart)
        params["expiration_start"] =
          "" + formatDateForBackend(filter.expirationStart);
      if (filter.expirationEnd)
        params["expiration_end"] =
          "" + formatDateForBackend(filter.expirationEnd);
      if (filter.employeeQueryString)
        params["employee_query_string"] = filter.employeeQueryString;
      if (filter.orderQueryString)
        params["order_query_string"] = filter.orderQueryString;
      if (filter.onlyPrivate) params["only_private"] = "true";
    }
    if (per_page) {
      params["page"] = "" + page;
      params["per_page"] = "" + per_page;
      return this.httpClient.get<ListResultDTO<EmployeeDTO>>(
        this.ROUTES.employeesWithDeadline,
        {
          params: new HttpParams({ fromObject: params }),
        }
      );
    } else {
      return this.httpClient
        .get<EmployeeDTO[]>(this.ROUTES.employeesWithDeadline, {
          params: new HttpParams({ fromObject: params }),
        })
        .pipe(
          map((results) => {
            return {
              data: results,
              lenght: results.length,
            };
          })
        );
    }
  }

  public getDeadlines(
    page: number,
    per_page: number,
    order: string,
    direction: string,
    filter?: DeadlineFilter,
    include?: string[]
  ): Observable<ListResultDTO<DeadlineDTO>> {
    if (per_page) {
      return this.getPaginatedResults(
        page,
        per_page,
        order,
        direction,
        filter,
        include
      );
    } else {
      return this.getAllResults(order, direction, filter, include).pipe(
        map((results) => {
          return {
            data: results,
            total: results.length,
          };
        })
      );
    }
  }

  private getPaginatedResults(
    page: number,
    per_page: number,
    order: string,
    direction: string,
    filter?: DeadlineFilter,
    include?: string[]
  ): Observable<ListResultDTO<DeadlineDTO>> {
    let params = {};
    if (page) params["page"] = "" + page;
    if (per_page) params["per_page"] = "" + per_page;
    if (order) params["order"] = "" + order;
    if (direction) params["direction"] = "" + direction;
    if (include) params["include[]"] = include;
    if (filter) {
      if (filter.includeArchived) params["include_archived"] = "true";
      if (filter.employeeId) params["employee_id"] = "" + filter.employeeId;
      if (filter.orderId) params["order_id"] = "" + filter.orderId;
      if (filter.serviceId) params["service_id"] = "" + filter.serviceId;
      if (filter.orgId) params["org_id"] = "" + filter.orgId;
      if (filter.reminderStart)
        params["reminder_start"] =
          "" + formatDateForBackend(filter.reminderStart);
      if (filter.reminderEnd)
        params["reminder_end"] = "" + formatDateForBackend(filter.reminderEnd);
      if (filter.expirationStart)
        params["expiration_start"] =
          "" + formatDateForBackend(filter.expirationStart);
      if (filter.expirationEnd)
        params["expiration_end"] =
          "" + formatDateForBackend(filter.expirationEnd);
      if (filter.employeeQueryString)
        params["employee_query_string"] = filter.employeeQueryString;
      if (filter.orderQueryString)
        params["order_query_string"] = filter.orderQueryString;
    }

    return this.httpClient.get<ListResultDTO<DeadlineDTO>>(this.ROUTES.list, {
      params: new HttpParams({ fromObject: params }),
    });
  }

  private getAllResults(
    order: string,
    direction: string,
    filter?: DeadlineFilter,
    include?: string[]
  ): Observable<DeadlineDTO[]> {
    let params = {};

    if (order) params["order"] = "" + order;
    if (direction) params["direction"] = "" + direction;
    if (include) params["include[]"] = include;
    if (filter) {
      if (filter.includeArchived) params["include_archived"] = "true";
      if (filter.employeeId) params["employee_id"] = "" + filter.employeeId;
      if (filter.orderId) params["order_id"] = "" + filter.orderId;
      if (filter.serviceId) params["service_id"] = "" + filter.serviceId;
      if (filter.orgId) params["org_id"] = "" + filter.orgId;
      if (filter.reminderStart)
        params["reminder_start"] =
          "" + formatDateForBackend(filter.reminderStart);
      if (filter.reminderEnd)
        params["reminder_end"] = "" + formatDateForBackend(filter.reminderEnd);
      if (filter.expirationStart)
        params["expiration_start"] =
          "" + formatDateForBackend(filter.expirationStart);
      if (filter.expirationEnd)
        params["expiration_end"] =
          "" + formatDateForBackend(filter.expirationEnd);
      if (filter.employeeQueryString)
        params["employee_query_string"] = filter.employeeQueryString;
      if (filter.orderQueryString)
        params["order_query_string"] = filter.orderQueryString;
    }
    return this.httpClient.get<DeadlineDTO[]>(this.ROUTES.list, {
      params: new HttpParams({ fromObject: params }),
    });
  }

  public createDeadline(dto: DeadlineDTO): Observable<DeadlineDTO> {
    return this.httpClient.post<DeadlineDTO>(this.ROUTES.store, {
      deadline: dto,
    });
  }

  public updateDeadline(id: number, dto: DeadlineDTO): Observable<DeadlineDTO> {
    dto.id = id;
    return this.httpClient.put<DeadlineDTO>(this.ROUTES.store, {
      deadline: dto,
    });
  }

  public deleteDeadline(id) {
    let params = new HttpParams().set("id", "" + id);
    return this.httpClient.delete(this.ROUTES.delete, { params: params });
  }

  public batchUpdateDeadlines(
    deadlineIds: number[],
    data: any
  ): Observable<any> {
    //TODO mrosetti - gestire in modo furbo, lato BE o FE (Object.assign?)

    return this.httpClient.put(this.ROUTES.batchUpdateDeadlines, {
      deadline_ids: deadlineIds,
      data: data,
    });
  }

  sendReminder(deadlineIds, template, subject, sender) {
    return this.httpClient.post(this.ROUTES.sendReminder, {
      deadline_ids: deadlineIds,
      template: template,
      subject: subject,
      sender: sender,
    });
  }

  sendReminderToOrgs(
    orgIds,
    content,
    subject,
    sender,
    senderInBcc,
    reminderStart,
    reminderEnd,
    expirationStart,
    expirationEnd,
    serviceId
  ): Observable<boolean> {
    return this.httpClient.post<boolean>(this.ROUTES.sendReminder, {
      org_ids: orgIds,
      content: content,
      subject: subject,
      sender: sender,
      sender_in_bcc: senderInBcc,
      reminder_start: formatDateForBackend(reminderStart),
      reminder_end: formatDateForBackend(reminderEnd),
      expiration_start: formatDateForBackend(expirationStart),
      expiration_end: formatDateForBackend(expirationEnd),
      service_id: serviceId,
    });
  }

  sendReminderToEmployees(
    employeeIds,
    content,
    subject,
    sender,
    senderInBcc,
    reminderStart,
    reminderEnd,
    expirationStart,
    expirationEnd,
    serviceId
  ): Observable<boolean> {
    return this.httpClient.post<boolean>(this.ROUTES.sendReminder, {
      employee_ids: employeeIds,
      content: content,
      subject: subject,
      sender: sender,
      sender_in_bcc: senderInBcc,
      reminder_start: formatDateForBackend(reminderStart),
      reminder_end: formatDateForBackend(reminderEnd),
      expiration_start: formatDateForBackend(expirationStart),
      expiration_end: formatDateForBackend(expirationEnd),
      service_id: formatDateForBackend(serviceId),
    });
  }

  checkExistingDeadlineForEmployee(
    orderId: number,
    employeeId: number,
    includeArchived?: boolean
  ): Observable<boolean> {
    let params = {};
    if (orderId) params["order_id"] = "" + orderId;
    if (employeeId) params["employee_id"] = "" + employeeId;
    if (includeArchived) params["include_archived"] = "true";
    return this.httpClient
      .get<number>(this.ROUTES.checkExistingDeadlineForEmployee, {
        params: new HttpParams({ fromObject: params }),
      })
      .pipe(map((count) => count > 0));
  }
}
