import { Service } from "./../_models/service";
import { Action } from "@ngrx/store";
import { User } from "../_models/user";

export enum ActionTypes {
  SetCurrentUser = "[App Status] Current user updated",
  SignIn = "[App Status] User logged in",
  SignOut = "[App Status] User logged out",
  LoadServices = "[App Status] Load Services",
  CurrentPage = "[App Status] Current Page"
}

export class SignIn implements Action {
  readonly type = ActionTypes.SignIn;

  constructor(public payload: { user: User }) { }
}

export class SetCurrentUser implements Action {
  readonly type = ActionTypes.SetCurrentUser;

  constructor(public payload: { user: User }) { }
}

export class SignOut implements Action {
  readonly type = ActionTypes.SignOut;

  constructor() { }
}

export class LoadServices implements Action {
  readonly type = ActionTypes.LoadServices;

  constructor(public payload: { services: Service[] }) { }
}

export class CurrentPage implements Action {
  readonly type = ActionTypes.CurrentPage;

  constructor(public payload: { title: string, slug: string }) { }
}

export type ActionsUnion =
  | SignIn
  | SetCurrentUser
  | SignOut
  | LoadServices
  | CurrentPage;
