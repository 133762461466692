import { OrgService } from "../../_services/org.service";
import { Component, OnInit, ViewChild, Inject, Optional } from "@angular/core";
import {
  MatPaginator,
  MatSort,
  MatDialogRef,
  MAT_DIALOG_DATA
} from "@angular/material";
import { merge } from "rxjs";
import { tap } from "rxjs/operators";

import { OrgsDataSource } from "../../home/orgs/org-list/orgs.datasource";
import { OrgsFilter } from "../../_models/org";

@Component({
  selector: "app-org-selection-dialog",
  templateUrl: "./org-selection-dialog.component.html",
  styleUrls: ["./org-selection-dialog.component.scss"]
})
export class OrgSelectionDialogComponent implements OnInit {
  displayedColumns = ["name", "VAT", "city", "contact", "action"];
  dataSource: OrgsDataSource;

  pageSizeOptions = [10, 25, 50, 100];
  queryString: string;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  multiple: boolean;

  onlyPartners: boolean = false;

  constructor(
    private orgService: OrgService,
    public dialogRef: MatDialogRef<OrgSelectionDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    if (this.data) {
      this.multiple = this.data.multiple;
      this.onlyPartners = this.data.onlyPartners;
    }
    this.dataSource = new OrgsDataSource(this.orgService);
    this.loadOrgs();
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.loadOrgs()))
      .subscribe();
  }

  loadOrgs() {
    return this.dataSource.loadOrgs(
      this.paginator.pageIndex + 1,
      this.paginator.pageSize,
      this.sort.active,
      this.sort.direction,
      this.getOrgsFilter()
    );
  }

  private getOrgsFilter(): OrgsFilter {
    return {
      queryString: this.queryString,
      onlyPartners: this.onlyPartners
    };
  }

  reload() {
    this.paginator.pageIndex = 0;
    this.paginator._changePageSize(this.paginator.pageSize);
    this.loadOrgs();
  }

  selectOrg(org) {
    this.dialogRef.close(org);
  }

  close() {
    this.dialogRef.close();
  }
}
