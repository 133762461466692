import { AbstractControl } from '@angular/forms';

import { CommonObject, CommonObjectDTO } from './common-object';

export interface ServiceFilter {
  queryString?: string;
  includeArchived?: boolean;
}

export interface EducationUnitFilter {
  queryString?: string;
  includeArchived?: boolean;
}

export interface ServiceDTO extends CommonObjectDTO {
  code: string;
  name: string;
  parent_services?: ServiceDTO[];
  category?: string;
  program?: string;
  description?: string;
  expiration?: number;
  minimum?: number;
  note?: string;
  details_url?: string;
  education_units?: EducationUnitDTO[];
  certificate_options?: CertificateOptionsDTO;
}

export class Service extends CommonObject {
  code: string;
  name: string;
  parentServices?: Service[];
  category?: string;
  program?: string;
  description?: string;
  expiration?: number;
  minimum?: number;
  note?: string;
  detailsUrl?: string;
  educationUnits?: EducationUnit[];
  certificateOptions?: CertificateOptions;

  constructor(dto: ServiceDTO, loadedRelations?: string[]) {
    super(dto, loadedRelations);
    if (dto) {
      this.code = dto.code;
      this.name = dto.name;
      if (dto.parent_services) {
        this.parentServices = dto.parent_services.map(
          parent_dto => new Service(parent_dto)
        );
        this.addLoadedRelation("parent_services");
      }
      if (dto.education_units) {
        this.educationUnits = dto.education_units.map(
          unit_dto => new EducationUnit(unit_dto)
        );
        this.addLoadedRelation("education_units");
      }
      this.category = dto.category;
      this.program = dto.program;
      this.description = dto.description;
      this.expiration = dto.expiration;
      this.note = dto.note;
      this.detailsUrl = dto.details_url;
      this.minimum = dto.minimum;
      if (dto.certificate_options) {
        this.certificateOptions = new CertificateOptions(
          dto.certificate_options
        );
      }
    }
  }

  public toDTO(): ServiceDTO {
    let result: ServiceDTO = <ServiceDTO>super.toDTO();
    Object.assign(result, {
      code: this.code,
      name: this.name,
      parent_service_ids: this.parentServices
        ? this.parentServices.map(parent => parent.objectId)
        : [],
      description: this.description,
      category: this.category,
      program: this.program,
      expiration: this.expiration,
      note: this.note,
      details_url: this.detailsUrl,
      minimum: this.minimum,
      certificate_options: this.certificateOptions ? this.certificateOptions.toDTO() : null,
      education_unit_ids: this.educationUnits
        ? this.educationUnits.map(unit => unit.objectId)
        : []
    });
    return result;
  }

  static fromFormGroup(
    formGroup: AbstractControl,
    original?: Service
  ): Service {
    const formModel = formGroup.value;

    let service: Service = new Service(null);

    service.code = formModel.code;
    service.name = formModel.name;
    service.category = formModel.category;
    service.program = formModel.program;
    service.description = formModel.description;
    service.parentServices = formModel.parentServices;
    service.expiration = formModel.expiration;
    service.minimum = formModel.minimum;
    service.note = formModel.note;
    service.detailsUrl = formModel.detailsUrl;
    service.educationUnits = formModel.educationUnits;
    service.certificateOptions = CertificateOptions.fromFormGroup(formGroup.get('certificateOptions'));
    if (original) {
      service.objectId = original.objectId;
      service.loadedRelations = original.loadedRelations;
    }
    return service;
  }

  get archived(): boolean {
    return this.deletedAt != null;
  }
}

export interface EducationUnitDTO extends CommonObjectDTO {
  code: string;
  name: string;
  color?: string;
  contents?: string;
  service?: ServiceDTO;
  description?: string;
  has_test?: boolean;
  hours?: number;
  note?: string;
}

export class EducationUnit extends CommonObject {
  code: string;
  name: string;
  color?: string;
  contents?: string;
  service?: Service;
  description?: string;
  hasTest?: boolean;
  hours?: number;
  note?: string;

  constructor(dto: EducationUnitDTO, loadedRelations?: string[]) {
    super(dto, loadedRelations);
    if (dto) {
      this.code = dto.code;
      this.name = dto.name;
      this.color = dto.color;
      this.contents = dto.contents;
      this.hasTest = dto.has_test;
      if (dto.service) {
        this.service = new Service(dto.service);
        this.addLoadedRelation("service");
      }
      this.description = dto.description;
      this.hours = dto.hours;
      this.note = dto.note;
    }
  }


  public toDTO(): EducationUnitDTO {
    let result: EducationUnitDTO = <EducationUnitDTO>super.toDTO();
    Object.assign(result, {
      code: this.code,
      name: this.name,
      color: this.color,
      has_test: this.hasTest,
      contents: this.contents,
      service_id: this.service ? this.service.objectId : null,
      description: this.description,
      hours: this.hours,
      note: this.note
    });
    return result;
  }

  static fromFormGroup(
    formGroup: AbstractControl,
    original?: EducationUnit
  ): EducationUnit {
    const formModel = formGroup.value;

    let educationUnit: EducationUnit = new EducationUnit(null);

    educationUnit.code = formModel.code;
    educationUnit.name = formModel.name;
    educationUnit.color = formModel.color;
    educationUnit.contents = formModel.contents;
    educationUnit.description = formModel.description;
    educationUnit.hours = formModel.hours;
    educationUnit.note = formModel.note;
    educationUnit.hasTest = formModel.hasTest;
    if (original) {
      educationUnit.objectId = original.objectId;
      educationUnit.loadedRelations = original.loadedRelations;
    }
    return educationUnit;
  }

  get archived(): boolean {
    return this.deletedAt != null;
  }
}

export interface CertificateOptionsDTO {
  type?: string;
  note?: string;
  print_deadline?: boolean;
  certificate_name?: string;
}

export class CertificateOptions {
  type?: string;
  note?: string;
  printDeadline?: boolean;
  name?: string;

  constructor(dto: CertificateOptionsDTO) {
    if (dto) {
      this.type = dto.type;
      this.note = dto.note;
      this.printDeadline = dto.print_deadline;
      this.name = dto.certificate_name || 'default';
    }
  }

  public toDTO(): CertificateOptionsDTO {
    return {
      type: this.type,
      note: this.note,
      print_deadline: this.printDeadline,
      certificate_name: this.name
    }
  }

  static fromFormGroup(
    formGroup: AbstractControl
  ): CertificateOptions {
    const formModel = formGroup.value;

    let options: CertificateOptions = new CertificateOptions(null);

    options.type = formModel.type;
    options.note = formModel.note;
    options.printDeadline = formModel.printDeadline;
    options.name = formModel.name;
    return options;
  }

}
