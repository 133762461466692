export class CommonObjectDTO {
  id?: number;
  created_at?: Date;
  updated_at?: Date;
  deleted_at?: Date;
  includes?: string[];
}

export class CommonObject {
  objectId?: number;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
  loadedRelations?: string[] = [];

  constructor(source: CommonObjectDTO, loadedRelations?: string[]) {
    if (source) {
      this.objectId = source.id;

      this.createdAt = source.created_at ? new Date(source.created_at) : null;
      this.updatedAt = source.updated_at ? new Date(source.updated_at) : null;
      this.deletedAt = source.deleted_at ? new Date(source.deleted_at) : null;
    }

    if (loadedRelations) {
      this.loadedRelations = loadedRelations;
    }
  }

  get archived(): boolean {
    return this.deletedAt != null;
  }

  public toDTO(): CommonObjectDTO {
    return {
      id: this.objectId,
      includes: this.loadedRelations
    };
  }

  public static compare(b1: CommonObject, b2: CommonObject): boolean {
    return b1 && b2 ? b1.objectId === b2.objectId : b1 === b2;
  }

  public addLoadedRelations(names: string[]) {
    if (names) {
      names.forEach(name => this.addLoadedRelation(name));
    }
  }

  public addLoadedRelation(name: string) {
    if (!this.loadedRelations) {
      this.loadedRelations = [];
    }
    if (this.loadedRelations.indexOf(name) == -1) {
      this.loadedRelations.push(name);
    }
  }

  public isRelationLoaded(name: string) {
    return this.loadedRelations
      ? this.loadedRelations.indexOf(name) != -1
      : false;
  }
}

export interface AddressDTO {
  street: string;
  ZIP: string;
  city: string;
  province: string;
  state?: string;
  note?: string;
}

export class Address {
  street: string;
  ZIP: string;
  city: string;
  province: string;
  state?: string;
  note?: string;

  constructor(dto: AddressDTO) {
    if (dto) {
      this.street = dto.street;
      this.ZIP = dto.ZIP;
      this.city = dto.city;
      this.province = dto.province;
      this.state = dto.state;
      this.note = dto.note;
    }
  }
}
