import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { NotFoundComponent } from "./commons/not-found/not-found.component";
import { AuthGuard } from "./_guards/auth.guard";

const routes: Routes = [
  {
    path: "",
    loadChildren: "./home/home.module#HomeModule",
    canActivate: [AuthGuard]
  },
  {
    path: "login",
    loadChildren: "./login/login.module#LoginModule"
  },
  { path: "**", component: NotFoundComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false // <-- debugging purposes only
    })
  ],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule {}
