import { environment } from "./../environments/environment";
import { HTTP_INTERCEPTORS, HttpClientModule, HttpErrorResponse } from "@angular/common/http";
import { APP_INITIALIZER, NgModule, LOCALE_ID } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MomentModule } from "angular2-moment";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";

import { AdminGuard } from "./_guards/admin.guard";
import { AuthGuard } from "./_guards/auth.guard";
import { TokenInterceptorService } from "./_helpers/token-interceptor.service";
import { LaravelConfigService } from "./_services/laravel";
import { UserService } from "./_services/user.service";
import { AppMaterialModule } from "./app-material/app-material.module";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app.routing";
import { ConfirmDialogComponent } from "./commons/confirm-dialog/confirm-dialog.component";
import { NotFoundComponent } from "./commons/not-found/not-found.component";
import { SharedModule } from "./commons/shared.module";
import { LoginModule } from "./login/login.module";
import { StoreModule } from "@ngrx/store";
import { reducers, metaReducers } from "./_reducers";
import {
  NgxUiLoaderModule,
  NgxUiLoaderHttpModule,
  NgxUiLoaderConfig,
  SPINNER
} from "ngx-ui-loader";

import localeIt from "@angular/common/locales/it";
import { registerLocaleData } from "@angular/common";
import { AlertDialogComponent } from "./commons/alert-dialog/alert-dialog.component";
import { catchError } from 'rxjs/operators';
registerLocaleData(localeIt);

export function configServiceFactory(
  laravelConfigService: LaravelConfigService
): Function {
  return () => laravelConfigService
    .load()
    .pipe(
      catchError(error => {
        if (error && error instanceof HttpErrorResponse) {
          if (error.status == 401) {
            return Promise.resolve(null);
          }
        }
        return Promise.reject(error);
      })
    )
    .toPromise();
}

export function initUserFactory(userService: UserService): Function {
  return () => userService.loadCurrentUser();
}

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  fgsType: SPINNER.threeStrings,
  hasProgressBar: false
};

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    ConfirmDialogComponent,
    AlertDialogComponent
  ],
  imports: [
    BrowserModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    AppMaterialModule,
    BrowserAnimationsModule,
    LoginModule,
    MomentModule,
    HttpClientModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreDevtoolsModule.instrument({
      maxAge: 10
    }),
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderHttpModule.forRoot({
      showForeground: true,
      exclude: [
        `${environment["laravel"]["serverUrl"]}/api/checkVATExisting`,
        `${environment["laravel"]["serverUrl"]}/api/checkIdentifierExists`
      ]
    })
  ],
  providers: [
    LaravelConfigService,
    {
      // Provider for APP_INITIALIZER
      provide: APP_INITIALIZER,
      useFactory: configServiceFactory,
      deps: [LaravelConfigService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initUserFactory,
      deps: [UserService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: "it-IT"
    },
    AuthGuard,
    AdminGuard,
    TokenInterceptorService
  ],
  bootstrap: [AppComponent],
  entryComponents: [ConfirmDialogComponent, AlertDialogComponent]
})
export class AppModule { }
