import { map } from "rxjs/operators";
import {
  RegistrationDTO,
  RegistrationFilter,
} from "../../_models/registration";
import { environment } from "../../../environments/environment";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { ListResultDTO } from "../../_helpers/listResult.interface";
import { ParticipantDTO } from "app/_models/planning";

@Injectable({ providedIn: "root" })
export class LaravelRegistrationService {
  constructor(private httpClient: HttpClient) {}

  private get ROUTES() {
    return {
      show: environment["laravel"]["serverUrl"] + "/api/registration",
      list: environment["laravel"]["serverUrl"] + "/api/registrations",
      count: environment["laravel"]["serverUrl"] + "/api/registrations/count",
      store: environment["laravel"]["serverUrl"] + "/api/registration",
      plan: environment["laravel"]["serverUrl"] + "/api/registration/plan",
      delete: environment["laravel"]["serverUrl"] + "/api/registration",
      deleteAllArchived:
        environment["laravel"]["serverUrl"] + "/api/registration/destroy",
      restore:
        environment["laravel"]["serverUrl"] + "/api/registration/restore",
    };
  }

  getRegistrationById(
    id: number,
    include?: string[]
  ): Observable<RegistrationDTO> {
    let params = {
      id: "" + id,
    };
    if (include) params["include[]"] = include;
    return this.httpClient.get<RegistrationDTO>(this.ROUTES.show, {
      params: new HttpParams({
        fromObject: params,
      }),
    });
  }

  public getRegistrations(
    page: number,
    per_page: number,
    order: string,
    direction: string,
    filter?: RegistrationFilter,
    include?: string[]
  ): Observable<ListResultDTO<RegistrationDTO>> {
    let params = {};
    if (order) params["order"] = "" + order;
    if (direction) params["direction"] = "" + direction;
    if (include) params["include[]"] = include;
    if (filter) {
      if (filter.includePlanned) params["include_planned"] = "true";
      if (filter.plannedCourseId)
        params["planned_course_id"] = "" + filter.plannedCourseId;
      if (filter.courseSearchQuery) {
        params["course_search_query"] = filter.courseSearchQuery;
      }
      if (filter.employeeSearchQuery) {
        params["employee_search_query"] = filter.employeeSearchQuery;
      }
      if (filter.onlyArchived) params["only_archived"] = "" + true;
    }

    if (per_page) {
      if (page) params["page"] = "" + page;
      if (per_page) params["per_page"] = "" + per_page;
      return this.httpClient.get<ListResultDTO<RegistrationDTO>>(
        this.ROUTES.list,
        {
          params: new HttpParams({ fromObject: params }),
        }
      );
    } else {
      return this.httpClient
        .get<RegistrationDTO[]>(this.ROUTES.list, {
          params: new HttpParams({ fromObject: params }),
        })
        .pipe(
          map((results) => {
            return {
              data: results,
              total: results.length,
            };
          })
        );
    }
  }

  public countRegistrations(): Observable<number> {
    return this.httpClient.get<number>(this.ROUTES.count);
  }

  public createRegistration(dto: RegistrationDTO): Observable<RegistrationDTO> {
    return this.httpClient.post<RegistrationDTO>(this.ROUTES.store, {
      registration: dto,
    });
  }

  public updateRegistration(
    id: number,
    dto: RegistrationDTO
  ): Observable<RegistrationDTO> {
    dto.id = id;
    return this.httpClient.put<RegistrationDTO>(this.ROUTES.store, {
      registration: dto,
    });
  }

  public planRegistration(
    registration_id: number,
    participant: ParticipantDTO
  ): Observable<RegistrationDTO> {
    return this.httpClient.post<RegistrationDTO>(this.ROUTES.plan, {
      registration_id: registration_id,
      participant: participant,
    });
  }

  public deleteRegistration(id) {
    let params = new HttpParams().set("id", "" + id);
    return this.httpClient.delete(this.ROUTES.delete, { params: params });
  }

  public deleteAlArchived() {
    return this.httpClient.delete(this.ROUTES.deleteAllArchived);
  }

  public restoreRegistration(id): Observable<RegistrationDTO> {
    return this.httpClient.put<RegistrationDTO>(this.ROUTES.restore, {
      id: id,
    });
  }
}
